.adm-page-body {
  width: 100%;
  height: 100%;
}

.dropdown-container {
  margin-bottom: 12px
}

.dropdown-container .adm-dropdown-item {
  background: var(--adm-color-background-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
}

.block-dropdown {
  display: block;
}

.hidden-dropdown {
  display: none;
}

.audio-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background: var(--adm-color-background-secondary) !important;
  height: 110px;
}

.picker-btn {
  background: var(--adm-color-background-secondary) !important;
  border-color: var(--adm-color-background-secondary) !important;
  margin-bottom: 12px !important;
  font-weight: normal !important;
  font-size: 15px !important;
}

.audio-download {
  width: 95% !important;
  /* margin-top: 5px !important; */
}

.rhap_container {
  height: 50px;
  width: 100%;
  background: var(--adm-color-background-secondary) !important;
  border-radius: 8px;
  font-weight: normal;
  font-size: 15px;

  display: flex;

  .rhap_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    border: solid 1px;
    margin: 10px;
    height: 50px;
    border-radius: 8px;
    color: silver;

    >* {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .rhap_progress-section {
      width: 80%;

      .rhap_progress-container {
        width: 60%;
        background: white;
        height: 5px;
      }
    }

    .rhap_controls-section {
      >* {
        >* {
          background: var(--adm-color-background-secondary);
          border: none;

          >* {
            font-size: 26px;
            color: white;
          }

          .rhap_volume-button {
            background: var(--adm-color-background-secondary);
            border: none;
          }
        }
      }
    }
  }
}

.adm-button.adm-button-mini {
  margin: 0px 10px 0px 10px;
}

.adm-page .adm-page-footer {
  margin-top: 10px !important;
}

@media screen and (min-width: 700px) {
  .page-content {
    display: flex;
    width: 100%;
    height: 100%;

    >* {
      width: 100%;
    }
  }

  .audio-content {
    height: 100%;
    border-radius: 8px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
  }

  .adm-popup-body-position-top {
    width: 50% !important;
  }

  .adm-mask {
    width: 50% !important;
  }
}

@media screen and (max-width: 699px) {
  .page-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.handle-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}